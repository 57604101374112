import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import InspirationItem from '../../components/inspiration/InspirationItem';
import Layout from '../../components/layouts/core';
import SeriesPage from '../../components/series/SeriesPage';

import muralForTheTerracePlazaHotel from '../../data/CollectionItems/muralForTheTerracePlazaHotel';

import muralForTheTerracePlazaHotelCarouselPiece from '../../data/CarouselPieces/muralForTheTerracePlazaHotelCarouselPiece';

import getImagesFromNodes from '../../utils/getImagesFromNodes';

export const pageQuery = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/mural-for-the-terrace-plaza-hotel-miro-print/" } }) {
      nodes {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(width: 600, height: 600, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;

const ogDescription = `Boogie-Woogie is an NFT collection from Dandy Lines.
In our Run 1 Miró Series, we focus on a large, vivid mural filled with a collection of organic shapes
and varying fields of color overlaying a visually textured blue background. The vivid
colors and the smaller-scale blocks generates a fun and engaging composition.`;

const RunOneMondrianPage = ({ data }) => {
  const images = getImagesFromNodes(data.allFile.nodes);

  return (
    <>
      <Helmet>
        <title>Boogie-Woogie | Run 1 Miró Series</title>
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@BoogieWoogieNFT' />
        <meta name='twitter:creator' content='@ics_jim' />
        <meta property='og:url' content='https://boogie-woogie.io/series/run-1-miro' />
        <meta property='og:title' content='Boogie-Woogie | Run 1 Miró Series' />
        <meta property='og:type' content='website' />
        <meta property='og:description' content={ogDescription} />
        <meta property='og:image' content='https://sale.boogie-woogie.io/boogie-woogie-auction-site-run-one.png' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
      </Helmet>
      <Layout>
        <SeriesPage details={muralForTheTerracePlazaHotel.details} seriesName='Run 1 Miró Series' images={images}>
          <InspirationItem
            ReferenceImage={muralForTheTerracePlazaHotel.ReferenceImage}
            details={muralForTheTerracePlazaHotel.details}
            seriesPage={muralForTheTerracePlazaHotel.seriesPage}
            piece={muralForTheTerracePlazaHotelCarouselPiece}
          />
        </SeriesPage>
      </Layout>
    </>
  );
};

export default RunOneMondrianPage;
